// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel'
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    /*&::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }*/
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

@import 'sections/section-projets';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';

h1, h2, h3, h4, h5 {
    font-family: 'Barlow';
}
p, div, li, a {
    font-family: 'Open Sans';
}

/***** POP-UP *****/

.blocPopUp {
    display: flex;
}
.blocPopUp .left-side h3 {
    font-family: 'Barlow';
    color: #000;
    font-weight: 500;
    font-size: $font-size-48;
    text-align: left;
    padding-bottom: 35px;
    text-transform: initial;
}
.blocPopUp .left-side p {
    color: #000 !important;
    line-height: 2 !important;
    font-weight: normal !important;
    font-size: $font-size-18 !important;
    text-align: left !important;
}
.blocPopUp .left-side {
    width: 50%;
    margin-right: 100px;
}
.blocPopUp .right-side {
    width: 45%;
}
.md-content.popup-box {
    max-width: 1250px;
    border: none;
    padding: 5% 5% 10% 5%;
    top: 4%;
    position: absolute;
    background: linear-gradient(to bottom, white 75%, transparent 100%);
}
div#modal-1 {
    background-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1));
    top: 0px;
    height: 2500px;
}


/***** TOP MENU *****/

nav.module-menu .menu-navbar .main-menu-top {
    background: #000;
    width: 100%;
    height: 50px;
}
nav.module-menu ul {
    display: flex;
    justify-content: flex-end;
    margin-top: -12px;
}
header nav.module-menu .menu-desktop {
    position: initial;
    background: url("../images/accueil_nav_transparence.png") no-repeat;
}
nav.module-menu .menu-navbar .main-menu-top ul li.icon {
    padding: 0 20px 0 0;
    display: flex;
    align-items: center;
}
nav.module-menu .menu-navbar .main-menu-top ul li.phone .icon {
    padding: 0 10px 0 0;
    display: flex;
    align-items: center;
}
nav.module-menu .menu-navbar .main-menu-top ul li.phone {
    padding-right: 1.5rem;
}
nav.module-menu .menu-navbar .main-menu-top ul li.phone div {
    font-weight: normal;
    font-size: $font-size-24;
    color: #fff;
}
nav.module-menu .menu-navbar .main-menu-top ul a div.icon img.hoverable.hover {
    display: none;
}
nav.module-menu .menu-navbar .main-menu-top ul li.phone:hover a div.icon img.hoverable.hover {
    display: block;
}
nav.module-menu .menu-navbar .main-menu-top ul li.phone:hover a div.icon img.hoverable.defaut {
    display: none;
}
nav.module-menu .menu-navbar .main-menu-top ul li.icon a img.hoverable.hover {
    display: none;
}
nav.module-menu .menu-navbar .main-menu-top ul li.icon:hover a img.hoverable.hover {
    display: block;
}
nav.module-menu .menu-navbar .main-menu-top ul li.icon:hover a img.hoverable.defaut {
    display: none;
}


/***** BANNIÈRE *****/

#hero .slideshow-wrap .caption .row .col-xs-12 img.img-mobile {
    display: none;
}


/***** MENU *****/

nav.module-menu .menu-navbar .menu-desktop .wrapper.right .main-menu ul li a div, nav.module-menu .menu-navbar .menu-desktop .wrapper.right .main-menu ul li a.menu-title {
    font-weight: normal;
    font-size: $font-size-24;
    color: #000;
    text-transform: initial;
    font-family: 'Open Sans';
}
nav.module-menu .menu-navbar .menu-desktop .wrapper.right .main-menu ul li:hover a div, nav.module-menu .menu-navbar .menu-desktop .wrapper.right .main-menu ul li:hover a.menu-title {
    font-weight: 600;
}
nav.module-menu .menu-desktop .wrapper.right {
    padding-right: 8.5rem;
}
nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li .submenu ul .subitem div, nav.module-menu .menu-navbar .menu-desktop .wrapper.right .main-menu ul li.dropdown .submenu ul.subitem-menu li.subitem a div {
    font-weight: normal;
    font-size: $font-size-20;
    color: #000;
    text-transform: initial;
    font-family: 'Open Sans';
}
nav.module-menu .menu-navbar .menu-desktop .wrapper.right .main-menu ul li.dropdown .submenu ul li:hover a div, nav.module-menu .menu-navbar .menu-desktop .wrapper.right .main-menu ul li.dropdown .submenu ul.subitem-menu li.subitem:hover a div {
    font-weight: 600;
    color: #000;
}

/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#blocIntro {
    padding: 75px 5%;

    h3 {
        font-weight: 500;
        font-size: $font-size-48;
        padding-bottom: 25px;
        color: #000;
        text-transform: initial;
    }

    p {
        font-size: $font-size-18;
        font-weight: normal;
    }

    .liste {
        display: flex;
        justify-content: space-evenly;
        padding-top: 55px;

        li {
            font-weight: 500;
            font-size: $font-size-30;
            color: #000;
            text-transform: initial;
            font-family: 'Barlow';
            margin-bottom: -25px;
            list-style: none;
            span {
                padding-right: 15px;
                font-weight: bold;
                font-family: 'Barlow';
            }
        }
    }
}

#blocBackground {
    background: url("../images/accueil_section02_bg.jpg") no-repeat;
    background-size: cover;
    padding: 275px 0;
    position: relative;

    h3 {
        font-weight: 500;
        font-size: $font-size-60;
        color: #fff;
        text-transform: initial;
    }

    h4 {
        font-weight: 500;
        font-size: $font-size-30;
        color: #fff;
        text-transform: initial;
    }

    .texte {
        position: absolute;
        top: 10%;
        padding: 0 5%;
    }
}

#blocServices {
    background: #000;
    padding: 25px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    h4 {
        font-weight: 500;
        font-size: $font-size-30;
        color: #fff;
    }
}

#blocTexte {
    padding: 50px 0;
    text-align: center;

    p {
        font-size: $font-size-18;
        font-weight: normal;
    }
}

#blocCitation {
    background: url("../images/accueil_section03_bg.jpg") no-repeat;
    background-size: cover;
    padding: 50px 0;
    display: flex;
    justify-content: space-around;

    h3 {
        font-weight: 600;
        font-size: $font-size-40;
        color: #000;
        text-align: center;
        text-transform: initial;
    }

    h4 {
        font-weight: 600;
        font-size: $font-size-24;
        color: #000;
        text-align: right;
        padding-top: 25px;
    }
}

#blocLogo {
    padding: 100px 2%;
    
    .logo {
        display: flex;
        justify-content: space-between; 
    }
    h3 {
        font-weight: 500;
        font-size: $font-size-30;
        color: #000;
        text-transform: initial;
        padding-bottom: 40px;
    }
    .reconnaissances {
        display: flex;
        padding-top: 25px;
        align-items: center;
        .bloc1 {
            padding: 0 10%;
        }  
    }
}


/*******************************************************************************
* SERVICES
*******************************************************************************/

#blocBackground_services {
    background: url("../images/services_section02_bg.jpg") no-repeat;
    background-size: cover;
    padding: 205px 3%;
    position: relative;

    h3 {
        font-weight: 500;
        font-size: $font-size-60;
        color: #fff;
        text-transform: initial;
    }

    h4 {
        font-weight: 500;
        font-size: $font-size-30;
        color: #fff;
        text-transform: initial;
    }

    .texte {
        position: absolute;
        top: 25%;
        padding: 0 5%;
    }
}

#blocImageTexte {
    display: flex;
    justify-content: space-evenly;
    padding: 75px 2%;
    align-items: center;

    h4 {
        font-weight: 500;
        color: #000;
        font-size: $font-size-30;
        text-transform: initial;
        line-height: 2;
    }
}

#blocLogo_services {
    padding: 100px 2%;
    display: flex;
    justify-content: space-around;   
}

/*******************************************************************************
* PRODUITS PERSONNALISÉS
*******************************************************************************/

#bloc50-50 {
    background: url("../images/produits_section02_bg.jpg") no-repeat;
    background-size: cover;
    padding: 100px 3% 100px 50%;

    li {
        font-weight: 500;
        font-size: $font-size-24;
        color: #000;
        text-transform: initial;
        font-family: 'Barlow';
        margin-bottom: -25px;
        list-style: none;
        span {
            padding-right: 15px;
            font-weight: bold;
            font-family: 'Barlow';
        }
    }
}


/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

h3.page-subtitle {
    font-weight: 500;
    color: #000;
    font-size: $font-size-48;
    text-transform: initial;
    padding: 25px 0;
}

.page-description p {
    font-size: $font-size-18;
    font-weight: normal;
    color: #000;
    padding-bottom: 25px;
}


/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#BlocCoordonnees {
    padding: 50px 2%;
    text-align: center;

    h3 {
        font-weight: 500;
        color: #000;
        font-size: $font-size-48;
        text-transform: initial;
        padding-bottom: 25px;
    }
    h5 {
        font-weight: 500;
        color: #000;
        font-size: $font-size-24;
        text-transform: initial;
    }
    h4 {
        font-weight: 500;
        color: #000;
        font-size: $font-size-30;
        text-transform: initial;
        line-height: 1.5;
    }
    .info {
        padding-top: 50px;
    }
}

#blocFormulaire {
    padding: 25px 0;

    #right {
        position: relative;
        left: 69%;
        bottom: 175px;
        width: 35%;

        label.image {
            color: #949494;
            border-bottom: 1px solid #949494;
            font-size: $font-size-24;
        }
    }

    label.control-label {
        color: #000;
        text-transform: uppercase;
        font-weight: bold;
    }
    form input.form-control, form textarea.form-control {
        color: #949494;
        font-weight: bold;
        border: 1px solid #000;
    }
}
a.btn.btn-send {
    display: none;
}
h3#firstHeading {
    font-weight: 500;
    color: #000;
    font-size: 1.5rem;
    text-transform: initial;
    line-height: 1.5;
}
#bodyContent p a {
    color: #000;
}
#bodyContent p:hover a {
   border-bottom: 1px solid #000;
}


/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1550px) {

#blocIntro p br {
    display: none;
}
#blocTexte {
    padding: 50px 3%;
}
#blocLogo .bloc {
    padding: 20px;
}
#blocImageTexte h4 br, #blocRealisations .bloc .texte p br {
    display: none;
}
#blocImageTexte .texte {
    width: 60%;
}
#bloc50-50 li {
    margin-bottom: 20px;
    line-height: 1.25;
}
#blocRealisations .bloc .texte {
    width: 70%;
}
}

@media screen and (max-width: 1400px) {

.section-projets--image {
    max-height: 350px;
    width: 100%;
}
}

@media screen and (max-width: $size-md-max) {

#blocBackground {
    padding: 200px 0;
}
nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li .submenu {
    top: 70px;
}
}

@media screen and (max-width: 1140px) {

nav.module-menu .menu-desktop .wrapper.right {
    padding-right: 1.5rem;
}
}

@media screen and (max-width: 1024px) {

nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li > a {
    padding-left: 0;
    padding-right: 10px;
}
#blocCitation {
    padding: 50px 2%;
}
#blocCitation h3 br {
    display: none
}
nav.module-menu .menu-desktop .wrapper.right {
    padding-right: 1.5rem;
}
#blocRealisations .bloc {
    flex-direction: column;
}
#blocRealisations .bloc .texte {
    width: 46.5%;
    margin: auto;
}
#blocRealisations .bloc .image {
    margin: auto;
}
.module-header {
    padding-top: 50px;
}
#blocImageTexte .texte {
    width: 100%;
    padding-left: 55px;
}
#blocFormulaire #right {
    left: 65%;
    width: 50%;
}
.btn {
    margin-left: 32%;
}
nav.module-menu #menu-mobile ul li > a {
    font-size: 16px;
}
}

@media screen and (max-width: $size-sm-max) {

nav.module-menu .menu-desktop .wrapper.right {
    padding-right: 1.5rem;
}
nav.module-menu ul {
    margin-top: -5px;
}
.blocPopUp {
    flex-direction: column;
}
.blocPopUp .left-side, .blocPopUp .right-side {
    width: 100%;
}
.md-content.popup-box {
    padding: 10% 5% 35% 5%;
}
nav.module-menu #menu-mobile ul li .submenu ul li.subitem a div {
    font-weight: 600;
}
nav.module-menu #menu-mobile ul li .submenu ul li.subitem {
    display: flex;
    flex-direction: column;
}
nav.module-menu #menu-mobile ul li .submenu ul li.subitem ul {
    border-left: none;
    padding: 10px 0 10px 15%;
    align-items: center;
}
nav.module-menu #menu-mobile ul li .submenu ul li.subitem ul li {
    padding: 10px 20px;
}
#blocLogo .bloc {
    padding: 15px;
}
nav.module-menu #menu-mobile ul li .submenu ul li.subitem ul li {
    text-align: center;
}
#blocRealisations .bloc .texte {
    width: 62%;
}
#blocBackground_services {
    padding: 160px 3%;
}
#bloc50-50 {
    padding: 100px 3%;
}
.btn {
    margin-left: 21%;
}
#blocFormulaire #right {
    left: 60%;
}
}


@media screen and (max-width: $size-xs-max) {

#blocIntro h3 br {
    display: none;
}
#blocBackground {
    padding: 105px 0;
}
#blocBackground .texte {
    padding: 0 10% 0 5%;
}
#blocServices {
    flex-direction: column;
}
#blocServices .bloc {
    padding-bottom: 15px;
    text-align: center;
}
#blocCitation {
    padding: 50px 0%;
}
#blocCitation .flecheGauche, #blocCitation .flecheDroite {
    margin-top: 35px;
}
#blocLogo .logo, #blocLogo_services {
    padding: 50px 0;
    flex-wrap: wrap;
    text-align: center;
}
#blocLogo .bloc, #blocLogo_services .bloc {
    padding: 15px;
    width: 180px;
}
#blocLogo h3 {
    padding-bottom: 0;
}
.slideshow-wrap .caption {
    top: 40%;
    padding: 0;
}
nav.module-menu ul {
    margin-top: 0px;
}
#blocIntro {
    padding: 50px 4%;
}
.md-content.popup-box {
    padding: 10% 5% 45% 5%;
    top: 2%;
}
nav.module-menu #menu-mobile ul li .submenu ul li.subitem .menu-title {
    margin-right: 0;
}
#blocRealisations .bloc .texte {
    width: 100%;
}
#blocRealisations {
    padding: 100px 2%;
}
#blocIntro .liste {
    padding-top: 20px;
    flex-direction: column;
}
#blocIntro .liste .right-side {
    margin-top: -10px;
}
#blocImageTexte {
    flex-direction: column;
}
#blocImageTexte .texte {
    padding-left: 0;
    text-align: center;
    padding-top: 20px;
}
#blocFormulaire #right {
    position: initial;
    width: 100%;
}
.btn {
    margin-left: 0;
}
#BlocCoordonnees h4 {
    font-size: 1.3rem;
}
.section-projets {
    padding: 100px 2% 50px 2%;
}
#blocLogo .reconnaissances {
    flex-direction: column;
    text-align: center;
}
#blocLogo .reconnaissances .bloc1 {
    padding: 10%;
}
}
